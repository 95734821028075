<script lang="ts" setup>
import { Switch as HSwitch } from "@headlessui/vue";
import { twMerge, twJoin } from "tailwind-merge";

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(
  defineProps<{
    name?: string;
    modelValue?: boolean;
    label?: string;
    required?: boolean;
    disabled?: boolean;
  }>(),
  {
    modelValue: false,
  },
);
const { emitFormChange, formGroup } = useFormGroup();
const inputId = computed(() => formGroup?.name.value || props.name);

const active = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    emitFormChange();
  },
});

const switchClass = computed(() => {
  return twMerge(
    twJoin(
      [
        "relative inline-flex h-6 w-12 flex-shrink-0 border focus:outline-none",
        "rounded-full",
        "focus-visible:ring-2 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400 focus-visible:ring-offset-2 focus-visible:ring-offset-white dark:focus-visible:ring-offset-gray-900",
        "disabled:cursor-not-allowed",
      ],
      active.value
        ? "bg-blue-500 dark:bg-blue-400 border-transparent disabled:bg-blue-200 disabled:dark:bg-blue-800"
        : "bg-neutral-light-700 border-transparent dark:bg-neutral-dark-500 disabled:dark:bg-neutral-dark-600 dark:border-transparent disabled:dark:border-neutral-dark-500 disabled:bg-neutral-light-50 disabled:border-neutral-light-300",
    ),
  );
});
const switchCircleClass = computed(() => {
  return twMerge(
    twJoin(
      "pointer-events-none relative inline-block h-[1.125rem] w-[1.125rem] rounded-full transform ring-0 transition ease-in-out duration-200 bg-white",
      active.value
        ? "translate-y-0.5 translate-x-[1.625rem] rtl:-translate-x-6"
        : "translate-y-0.5 translate-x-0.5 rtl:-translate-x-0",
      props.disabled
        ? active.value
          ? "bg-neutral-light-50 dark:bg-neutral-dark-200"
          : "bg-neutral-light-300 dark:bg-neutral-dark-400"
        : null,
    ),
  );
});
</script>

<template>
  <div class="flex items-center">
    <HSwitch
      :id="inputId"
      v-model="active"
      :name="name"
      :disabled="disabled"
      :class="switchClass"
    >
      <span :class="switchCircleClass" />
    </HSwitch>

    <label
      v-if="props.label || $slots.label"
      :for="inputId"
      :class="[
        'ms-3 text-sm font-medium text-black dark:text-white',
        props.required && 'u-label-text--required',
        props.disabled && 'cursor-not-allowed text-neutral-light-700 dark:text-neutral-dark-300',
      ]"
    >
      <slot name="label">{{ props.label }}</slot>
    </label>
  </div>
</template>
